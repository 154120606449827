.footer {
  background: #3f2317;
  border-top: 4px solid #5271ff;
  box-shadow: -.25rem 0 .5rem #b3b3b3;
  color: rgb(240, 240, 240);
  margin-top: 1.5rem;
  overflow: hidden;

  &__logo {
    max-width: 13.75rem;
    margin-top: -1.5rem;
  }

  &__title {
    color: #fff;
    display: inline-block;
    margin-bottom: 1rem;

    &:after {
      content: '';
      background: url('../../assets/img/line.svg') no-repeat;
      display: block;
      // border-bottom: 3px solid #f37f22;
      height: 4px;
      overflow: hidden;
      width: 110%;
      margin: .25rem -.25rem;
    }
  }

  &__link {
    color: #dedede;

    &:hover {
      color: #fff;
    }
  }

  address {
    font-size: 0.85rem;
    // line-height: 1.7rem;

    a {
      color: #ffffffcb;
      &:hover {
        color: #fff;
      }
    }
  }

  &__icon {
    display: inline-block;
    color: #fff;
    line-height: 1rem;
    margin: 0 0.35rem;
    font-size: 1.3rem;

    &:hover {
      color: #fff;
    }
  }

  &__map {

    height: 20rem;
    overflow: hidden;
    border-radius: 0.75rem 0.75rem 0 0;

    &~address {
      font-size: 1rem;
      background: #be1622;
      color: #fff;
      line-height: 1.3rem;
      padding: 0.25rem;
      border-radius: 0 0 0.75rem 0.75rem;
      box-shadow: 0 0.25rem 0.5rem #00000036;

      a {
        color: #fff;
      }
    }

    &>div {
      position: relative;
    }
  }

  &__copy {
    background: #272f33;
    color: #fff;
    font-size: 0.8125rem;
    padding: 0.65rem 0;
    position: relative;
    z-index: 100;

    a {
      color: #fff;

      &:hover {
        color: #fff;
      }
    }
  }
}

.float {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 96px;
  right: 15px;
  background-color: #25d366;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3215686274509804);
  z-index: 200;
  line-height: 50px;
  opacity: 0.8;
  transition: all 0.5s ease-in-out;

  &:hover {
    color: #25d366;
    background-color: #fff;
    opacity: 1;
  }

  &_1 {
    bottom: 152px;
  }
}

.my-float {
  margin-top: 9px;
}

.float_1 {
  bottom: 152px;
}

.float,
.footer__copy a,
.footer__copy a:hover {
  color: #fff;
}