.esige-profile {
     background: white;
     box-shadow: 1px 1px 3px #dcdcdc;
     flex: 0 0 100%;
     display: flex;
     flex-wrap: wrap;
     position: relative;
     .esige-profile-loader {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%);
     }
     .esige-profile-pedidos {
          flex: 0 0 70%;
          max-width: 70%;
          position: relative;
          padding-right: 20px;
     }
     .esige-profile-container {
          flex: 0 0 30%;
          max-width: 30%;
          position: relative;
          flex-wrap: wrap;
     }
}
input.mgp1::-webkit-outer-spin-button, input.mgp1::-webkit-inner-spin-button, input.mgp2::-webkit-outer-spin-button, input.mgp2::-webkit-inner-spin-button {
     -webkit-appearance: none;
     margin: 0;
     text-align: right;
}
.mgp1{
     max-width: 250px;
}
input.mgp1[type=number], input.mgp2[type=number] {
     -moz-appearance:textfield;
     text-align: right;
}

.row-articulos div {
     margin-top: auto;
     margin-bottom: auto;
}

.price-col {
     text-align: right;
     font-weight: 900;
}

.total-row {
     .price-col {
          font-size: 20px;
     }
}
.btn-margen-tabla-cell{
     padding: 2px 10px !important;
}
