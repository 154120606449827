:root{
  --destacados-buttons-background-color: #019FC4 !important;
	--destacados-buttons-border-color: #019FC4 !important;
	--destacados-buttons-color: #019FC4 !important;
  
	--producto-box-price-background-color: #274463 !important;
	--producto-box-price-border-color: #019FC4 !important;
	--producto-box-price-color: #274463 !important;
  
	--producto-box-off-background: var(--second-color) !important;
}

//loader
.sc-htpNat{
	margin-top: 255px;
	height: calc(100vh - 255px);
}