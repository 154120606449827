.estadoCC-Container {
  @media screen and (min-width:450px) {
    min-height: 450px;
  }
	table.ctaCorriente {
    a.a-blue,
    a.a-blue:hover,
    a.a-blue:active {
      color: blue;
      cursor: pointer;
    }

    @media screen and (max-width: 600px) {
      display: block;
      border: none;
      width: 99%;
      thead > tr.d-sm-table-row.d-xs-table-row.d-md-none.d-lg-none.d-xl-none {
        td {
          display: inline-table;
          width: 99.9%;
          clear: both;
        }
      }
      tbody > tr {
        display: flex;
        flex-direction: column;
        width: 100vw;
        padding: 0.75rem 0;
        & > td {
          border-top: none;
          padding: 0.2rem 0.7rem;
          &:before {
            content: "Fecha:";
            display: inline-block;
            min-width: 160px;
            padding: 0 8px;
            text-align: right;
            width: 40%;
          }
          &:nth-child(2):before {
            content: "Fecha de venc.:";
          }
          &:nth-child(3):before {
            content: "Concepto:";
          }
          &:nth-child(4):before {
            content: "Leyenda:";
          }
          &:nth-child(5):before {
            content: "Nro. Comprobante:";
          }
          &:nth-child(6):before {
            content: "Importe:";
          }
          &:nth-child(7):before {
            content: "Aplicado:";
          }
          &:nth-child(8):before {
            content: "Saldo:";
          }
          &:nth-child(9):before {
            content: "Acumulado:";
          }

          &.noContent:before {
            content: "";
            display: none;
          }
        }
      }
    }
  }
}
