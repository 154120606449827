.bgBreadcrumb {
  background: #f4f4f4;

  ol.breadcrumb {
    background-color: transparent;
    margin: 0 -1rem;
  }

  .breadcrumb-item>a {
    color: #6c757d;
  }
}

.product {
  &__price {
    color: #f18318;
    font-size: 2.8125rem;
    font-weight: bold;
    line-height: 2.1rem;
    margin-top: 2rem;
    text-align: center;

    &:after {
      color: #29aa59;
      content: 'Precio Contado';
      display: block;
      font-size: 0.85rem;
    }
  }
}

h3 {
  font-weight: 700 !important;
  color: #6c757d;
  text-align: center;
}

.priceOld {
  color: #9d9d9c;
  display: flex;
  margin: 0;
  justify-content: center;
}

.product__detail {

  &>h4 {
    display: inline-block;
    color: #6c757d;
    margin-bottom: 1rem;

    &:after {
      content: "asd";
      background: url('../../assets/img/line.svg') no-repeat;
      display: block;
      height: 4px;
      overflow: hidden;
      width: 82%;
      margin: 0.25rem -0.25rem;
      background-position: -0.5rem;
      transition: all 0.5s ease-in-out;
    }
  }

  &:hover>h4:after {
    background-position: .5rem;
  }

  &--border {
    border-bottom: 3px solid #f18318;
  }
}

.border {
  border: 1px solid #9d9d9c;
}

.cFacebook {
  color: #3c5a97;
}

.cWhatsapp {
  color: #51b15d;
}

.btn-success.btn-success__light {
  background-color: #28a74644;
  color: #188a32;

  &:hover {
    background-color: #28a7465e;
    color: #107427;
  }
}

.esige-producto .esige-producto-container .esige-producto-main-detail .esige-carousel {
  .carousel-control-prev:hover,
  .carousel-control-next:hover {
    background-color: #f7921c3b !important;
  }
}