.login {
    
    &__wrap {
        height: calc(calc(100vh - 44px) - 1.5rem);
        display: flex;
        justify-content: center;
        align-items: center;

        a {
            color: #fff !important;
        }
    }

    &__form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__logo {
        min-width: 220px;
        margin-bottom: 2rem;
    }


}