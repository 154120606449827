header {
	background: transparent;
	box-shadow: 0 0 5px #2c37498a;
	z-index: 999;

	// &:after {
	// 	content: ' ';
	// 	background: #2c3749;
	// 	height: 2px;
	// 	display: block;
	// }

}

.navbar {
	background: transparent;
}

.navbar-brand>img {

	@media screen and (max-width: 767px) {
		max-width: 15rem;
	}

}

.navbar-toggler {
	border-color: #2c3749;
	padding: .5rem .75rem;
}


@media screen and (max-width: 767px) {
	.navbar-collapse {
		border-top: 1px dotted #2c3749;
		margin-top: .5rem;
	}
}

// .nav-link.btn__clientes {
// 	border: 1.5px solid var(--primary-color);
// 	border-radius: .5rem;
// 	color: var(--primary-color) !important;
// 	padding: .25rem 1rem;
// 	height: 2.2rem;
//     margin-top: 3px;

// 	@media screen and (min-width: 992px) {
// 		margin-left: 1rem;
// 	}

// }
// .nav-link.btn__clientes:hover{
// 	background-color: var(--primary-color) !important;
// 	border-color: var(--primary-color) !important;
// 	color: #fff !important;
// }

.popover-menu-account {
	.nav-link.active {
		color: var(--primary-color) !important;
	}

	.nav-link {
		color: rgba(0, 0, 0, .5);
	}
}


// .nav-item {
// 	a {
// 		cursor: pointer;
// 		text-align: center;
// 		font-size: .875rem;
// 		color: #2c3749;
// 		letter-spacing: 1px;
// 	}

// 	&.active {
// 		position: relative;
// 		text-align: center;
// 	}
// }