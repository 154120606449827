.destacados-section {
	padding: 1rem 0;

	&__title {
		text-align: center;
		text-transform: uppercase;
		color: #555;

		h2 {
			font-weight: 700;
			font-size: 2.625rem;
			margin-bottom: 0;
		}
		h3 {
			font-weight: 300;
			font-size: 2rem;
		}

	}
}
.carouselDestacos {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	grid-gap: 1rem;
}

.pDestacado {
	border: 1px solid #D9D9D9;
	padding: .5rem;

	&__figure {
		padding: .5rem 0;
		position: relative;
	}
	&__img {
		max-width: 100%;
	}
	&__off {
		position: absolute;
		left: -.25rem;
		top: -.25rem;
		background: var(--producto-box-off-background);
		padding: .6rem;
		color: #fff;
		line-height: 1rem;
		border-radius: 0 1.5rem 1.5rem 1.5rem;
	}
	&__title {
		font-size: .85rem;
		line-height: 1rem;
	}
	&__prices {
		display: flex;
		justify-content: space-around;
	}
	&__pricesOff {
		color: #9B9B9B;
		text-decoration: line-through;
		display: flex;
		align-items: center;
	}
	&__price {
		color: var(--producto-box-off-background);
		line-height: 1.1rem;
		font-weight: 700;
		text-align: center;
	}
	.btn {
		width: 100%;
		margin-bottom: .25rem;
		text-transform: uppercase;
	}
}

.destacados-carousel-indicators{
     border-radius: 20px;
     background-color: var(--destacados-buttons-color);
     color:white;
     li{
          height: 5px;
     }
     li.active{
          background-color: var(--destacados-buttons-color);
     }
}

.DestacadosPrevButtonTop.carousel-control-prev, .DestacadosNextButtonTop.carousel-control-next{
     background-color: var(--destacados-buttons-color);
     height: 40px;
     margin-top: 20px;
     border-radius: 20px;
     opacity: 0.9;
}
.DestacadosPrevButtonBottom, .DestacadosNextButtonBottom{
     position: absolute;
     top: 0;
     bottom: 0;
     z-index: 1;
     display: flex;
     align-items: center;
     justify-content: center;
     width: 15%;
     color: #fff;
     text-align: center;
     opacity: 0.5;
     transition: opacity 0.15s ease;

     position: relative;
     background-color: var(--destacados-buttons-color);
     height: 40px;
     border-radius: 20px;
     opacity: 0.9;
}

.DestacadosPrevButtonBottom{
     width: 40%;
     margin-right: auto;
}
.DestacadosNextButtonBottom{
     margin-left: auto;
     width: 40%;
}

.DestacadosPrevButtonTop.carousel-control-prev:hover, .DestacadosNextButtonTop.carousel-control-next:hover,
.DestacadosPrevButtonBottom:hover, .DestacadosNextButtonBottom:hover
{
     opacity: 1;
     cursor: pointer;
}
.destacados-boton-ver-mas{
     border-color: var(--destacados-buttons-color) !important;
     color: var(--destacados-buttons-color) !important;
}
.destacados-boton-ver-mas:hover{
     background-color: var(--destacados-buttons-color) !important;
     border-color: var(--destacados-buttons-color) !important;
     color: #fff !important;
}

.react-multi-carousel-list {
	padding-bottom: 1rem;
    margin-bottom: -1rem;
}

.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left{
     left: 0;
     background-color: var(--destacados-buttons-color);
     min-width: 5%;
     opacity: 0.9;
}
.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left:hover, .react-multiple-carousel__arrow.react-multiple-carousel__arrow--right:hover{
     opacity: 1;
}
.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right{
     right: 0;
     background-color: var(--destacados-buttons-color);
     min-width: 5%;
     opacity: 0.9;
}
.react-multi-carousel-track {
     margin: 0 auto !important;
}
