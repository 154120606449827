h5 {
  font-weight: 400;
  font-size: 0.985rem;
  letter-spacing: -0.025rem;
}

.border-1 {
  border: 1px solid;
}

.bt-1 {
  border-top: 1px solid;
}

.border-color-primary {
  border-color: var(--second-color) !important;
}

.float-right {
  float: right;
}

.cursor-pointer {
  cursor: pointer;
}

.bb-none {
  border-bottom: none;
}

.showmore {
  cursor: pointer;
  color: var(--primary-color);
  font-weight: 500 !important;
}

.inputGroup {
  display: block;
  position: relative;
  label {
    border-radius: 4px;
    color: #000;
    cursor: pointer;
    display: block;
    font-size: 12px;
    overflow: hidden;
    padding: 3px 5px 3px 26px;
    position: relative;
    text-align: left;
    text-transform: uppercase;
    transition: color 200ms ease-in;
    width: 100%;
    z-index: 2;
    &:before {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      content: "";
      position: absolute;
      left: 0%;
      top: 50%;
      width: 80%;
      transform: translate(-50%, -50%) scale3d(1, 1, 1);
      transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
      opacity: 0;
      z-index: -1;
    }
    &:after {
      padding: 1px;
      content: "";
      width: 15px;
      height: 15px;
      font: normal normal normal 10px/1 none;
      color: var(--bg-content);
      border: 2px solid #d1d7dc;
      border-radius: 25%;
      z-index: 1;
      position: absolute;
      left: 5px;
      text-align: center;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      transition: all 200ms ease-in;
    }
    &:hover {
      background-color: #ccc;
    }
  }
  input:checked ~ label {
    &:before {
      transform: translate(-50%, -50%) scale3d(56, 56, 1);
      opacity: 1;
    }
    &:after {
      background-color: var(--primary-color) !important;
      border-color: var(--primary-color) !important;
    }
  }
  input {
    width: 32px;
    height: 32px;
    order: 1;
    z-index: 2;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    visibility: hidden;
  }
}
