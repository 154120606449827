section.contacto {
	.form-control {
		background: #dddddd38;

		&::placeholder,
		&>option {
			color: #406985;
		}

		&:focus {
			border-color: #f7921c;
			box-shadow: 0 0 0 0.2rem rgba(200, 200, 200, .25);
		}
	}

	.btn-primary {
		background-color: #f7921c;
		border-color: #d97602;
	}

	.form-control.is-invalid {
		background-color: #dc35450f;
        border-width: 2px !important;
	}

}