//Definicion de Variables

:root {
  --destacados-buttons-background-color: #6e1040 !important;
  --destacados-buttons-border-color: #6e1040 !important;
  --destacados-buttons-color: #6e1040 !important;

  --producto-box-price-background-color: #6e1040 !important;
  --producto-box-price-border-color: #6e1040 !important;
  --producto-box-price-color: #6e1040 !important;

  --producto-box-off-background: #6e1040 !important;
}

//Componentes de Tienda
@import "./components/CantidadSelector/styles.scss";
@import "./components/CheckoutButton/styles.scss";
@import "./components/LoginNavItem/styles.scss";
@import "./components/Navigation/styles.scss";
@import "./components/ProdOfertados/styles.scss";
@import "./components/ProdDestacados/styles.scss";
@import "./components/ProductoBox/styles.scss";
@import "./components/TiendaNavItem/styles.scss";
@import "./components/TopMenu/styles.scss";

// Paginas de Tienda
@import "./views/Productos/Filter/styles.scss";
@import "./views/Checkout/styles.scss";
@import "./views/Producto/styles.scss";
@import "./views/Productos/styles.scss";
@import "./views/Usuario/CuentasCorrientes/styles.scss";
@import "./views/Usuario/Perfil/styles.scss";
@import "./views/Usuario/Pedidos/styles.scss";

// Definicion de Clases

.width-inherit {
  width: inherit;
}
.esige-productos-list-loader{
  margin-top: 150px;
}

/* BS4 Grid XXL breakpoint - https://stackoverflow.com/questions/48924751/how-to-create-new-breakpoints-in-bootstrap-4-using-cdn*/

/* adding col-xxl */
.col-xxl, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl-auto {position: relative; width: 100%; padding-right: 15px; padding-left: 15px;}

@media (min-width: 1570px){
  /* expanding BS4 max-width */
  .container {max-width: 1540px;}

  /* grid columns xxl */
  .col-xxl {-ms-flex-preferred-size: 0; flex-basis: 0; -webkit-box-flex: 1; -ms-flex-positive: 1; flex-grow: 1; max-width: 100%;}
  .col-xxl-auto {-webkit-box-flex: 0; -ms-flex: 0 0 auto; flex: 0 0 auto; width: auto; max-width: none;}
  .col-xxl-1 {-webkit-box-flex: 0; -ms-flex: 0 0 8.333333%; flex: 0 0 8.333333%; max-width: 8.333333%;}
  .col-xxl-2 {-webkit-box-flex: 0; -ms-flex: 0 0 16.666667%; flex: 0 0 16.666667%; max-width: 16.666667%;}
  .col-xxl-3 {-webkit-box-flex: 0; -ms-flex: 0 0 25%; flex: 0 0 25%; max-width: 25%;}
  .col-xxl-4 {-webkit-box-flex: 0; -ms-flex: 0 0 33.333333%; flex: 0 0 33.333333%; max-width: 33.333333%;}
  .col-xxl-5 {-webkit-box-flex: 0; -ms-flex: 0 0 41.666667%; flex: 0 0 41.666667%; max-width: 41.666667%;}
  .col-xxl-6 {-webkit-box-flex: 0; -ms-flex: 0 0 50%; flex: 0 0 50%; max-width: 50%;}
  .col-xxl-7 {-webkit-box-flex: 0; -ms-flex: 0 0 58.333333%; flex: 0 0 58.333333%; max-width: 58.333333%;}
  .col-xxl-8 {-webkit-box-flex: 0; -ms-flex: 0 0 66.666667%; flex: 0 0 66.666667%; max-width: 66.666667%;}
  .col-xxl-9 {-webkit-box-flex: 0; -ms-flex: 0 0 75%; flex: 0 0 75%; max-width: 75%;}
  .col-xxl-10 {-webkit-box-flex: 0; -ms-flex: 0 0 16.666667%; flex: 0 0 16.666667%; max-width: 16.666667%;}
  .col-xxl-11 {-webkit-box-flex: 0; -ms-flex: 0 0 16.666667%; flex: 0 0 16.666667%; max-width: 16.666667%;}
  .col-xxl-12 {-webkit-box-flex: 0; -ms-flex: 0 0 16.666667%; flex: 0 0 16.666667%; max-width: 16.666667%;}

  /* order xxl */
  .order-xxl-0 {-webkit-box-ordinal-group: 1; -ms-flex-order: 0; order: 0;}
  .order-xxl-1 {-webkit-box-ordinal-group: 2; -ms-flex-order: 1; order: 1;}
  .order-xxl-2 {-webkit-box-ordinal-group: 3; -ms-flex-order: 2; order: 2;}
  .order-xxl-3 {-webkit-box-ordinal-group: 4; -ms-flex-order: 3; order: 3;}
  .order-xxl-4 {-webkit-box-ordinal-group: 5; -ms-flex-order: 4; order: 4;}
  .order-xxl-5 {-webkit-box-ordinal-group: 6; -ms-flex-order: 5; order: 5;}
  .order-xxl-6 {-webkit-box-ordinal-group: 7; -ms-flex-order: 6; order: 6;}
  .order-xxl-7 {-webkit-box-ordinal-group: 8; -ms-flex-order: 7; order: 7;}
  .order-xxl-8 {-webkit-box-ordinal-group: 9; -ms-flex-order: 8; order: 8;}
  .order-xxl-9 {-webkit-box-ordinal-group: 10; -ms-flex-order: 9; order: 9;}
  .order-xxl-10 {-webkit-box-ordinal-group: 11; -ms-flex-order: 10; order: 10;}
  .order-xxl-11 {-webkit-box-ordinal-group: 12; -ms-flex-order: 11; order: 11;}
  .order-xxl-12 {-webkit-box-ordinal-group: 13; -ms-flex-order: 12; order: 12;}

  /* text-align xxl */
  .text-xxl-center {text-align: center!important;}
  .text-xxl-left {text-align: left!important;}
  .text-xxl-right {text-align: right!important;}

  /* float xxl */
  .float-xxl-left {float: left!important;}
  .float-xxl-right {float: right!important;}
  .float-xxl-none {float: none!important;}

  /* display xxl */
  .d-xxl-none {display: none!important;}
  .d-xxl-inline {display: inline!important;}
  .d-xxl-inline-block {display: inline-block!important;}
  .d-xxl-block {display: block!important;}
  .d-xxl-table {display: table!important;}
  .d-xxl-table-cell {display: table-cell!important;}
  .d-xxl-table-row {display: table-row!important;}
  .d-xxl-flex {display: flex!important;}
  .d-xxl-inline-flex {display: inline-flex!important;}

  /* offsets xxl */
  .offset-xxl-1 {margin-left: 8.333333%;}
  .offset-xxl-2 {margin-left: 16.666667%;}
  .offset-xxl-3 {margin-left: 25%;}
  .offset-xxl-4 {margin-left: 33.333333%;}
  .offset-xxl-5 {margin-left: 41.666667%;}
  .offset-xxl-6 {margin-left: 50%;}
  .offset-xxl-7 {margin-left: 58.333333%;}
  .offset-xxl-8 {margin-left: 66.666667%;}
  .offset-xxl-9 {margin-left: 75%;}
  .offset-xxl-10 {margin-left: 83.333333%;}
  .offset-xxl-11 {margin-left: 91.666667%;}

  /* spacing xxl */
  .m-xxl-0{margin:0!important}.mt-xxl-0,.my-xxl-0{margin-top:0!important}.mr-xxl-0,.mx-xxl-0{margin-right:0!important}.mb-xxl-0,.my-xxl-0{margin-bottom:0!important}.ml-xxl-0,.mx-xxl-0{margin-left:0!important}.m-xxl-1{margin:.25rem!important}.mt-xxl-1,.my-xxl-1{margin-top:.25rem!important}.mr-xxl-1,.mx-xxl-1{margin-right:.25rem!important}.mb-xxl-1,.my-xxl-1{margin-bottom:.25rem!important}.ml-xxl-1,.mx-xxl-1{margin-left:.25rem!important}.m-xxl-2{margin:.5rem!important}.mt-xxl-2,.my-xxl-2{margin-top:.5rem!important}.mr-xxl-2,.mx-xxl-2{margin-right:.5rem!important}.mb-xxl-2,.my-xxl-2{margin-bottom:.5rem!important}.ml-xxl-2,.mx-xxl-2{margin-left:.5rem!important}.m-xxl-3{margin:1rem!important}.mt-xxl-3,.my-xxl-3{margin-top:1rem!important}.mr-xxl-3,.mx-xxl-3{margin-right:1rem!important}.mb-xxl-3,.my-xxl-3{margin-bottom:1rem!important}.ml-xxl-3,.mx-xxl-3{margin-left:1rem!important}.m-xxl-4{margin:1.5rem!important}.mt-xxl-4,.my-xxl-4{margin-top:1.5rem!important}.mr-xxl-4,.mx-xxl-4{margin-right:1.5rem!important}.mb-xxl-4,.my-xxl-4{margin-bottom:1.5rem!important}.ml-xxl-4,.mx-xxl-4{margin-left:1.5rem!important}.m-xxl-5{margin:3rem!important}.mt-xxl-5,.my-xxl-5{margin-top:3rem!important}.mr-xxl-5,.mx-xxl-5{margin-right:3rem!important}.mb-xxl-5,.my-xxl-5{margin-bottom:3rem!important}.ml-xxl-5,.mx-xxl-5{margin-left:3rem!important}
  .p-xxl-0{padding:0!important}.pt-xxl-0,.py-xxl-0{padding-top:0!important}.pr-xxl-0,.px-xxl-0{padding-right:0!important}.pb-xxl-0,.py-xxl-0{padding-bottom:0!important}.pl-xxl-0,.px-xxl-0{padding-left:0!important}.p-xxl-1{padding:.25rem!important}.pt-xxl-1,.py-xxl-1{padding-top:.25rem!important}.pr-xxl-1,.px-xxl-1{padding-right:.25rem!important}.pb-xxl-1,.py-xxl-1{padding-bottom:.25rem!important}.pl-xxl-1,.px-xxl-1{padding-left:.25rem!important}.p-xxl-2{padding:.5rem!important}.pt-xxl-2,.py-xxl-2{padding-top:.5rem!important}.pr-xxl-2,.px-xxl-2{padding-right:.5rem!important}.pb-xxl-2,.py-xxl-2{padding-bottom:.5rem!important}.pl-xxl-2,.px-xxl-2{padding-left:.5rem!important}.p-xxl-3{padding:1rem!important}.pt-xxl-3,.py-xxl-3{padding-top:1rem!important}.pr-xxl-3,.px-xxl-3{padding-right:1rem!important}.pb-xxl-3,.py-xxl-3{padding-bottom:1rem!important}.pl-xxl-3,.px-xxl-3{padding-left:1rem!important}.p-xxl-4{padding:1.5rem!important}.pt-xxl-4,.py-xxl-4{padding-top:1.5rem!important}.pr-xxl-4,.px-xxl-4{padding-right:1.5rem!important}.pb-xxl-4,.py-xxl-4{padding-bottom:1.5rem!important}.pl-xxl-4,.px-xxl-4{padding-left:1.5rem!important}.p-xxl-5{padding:3rem!important}.pt-xxl-5,.py-xxl-5{padding-top:3rem!important}.pr-xxl-5,.px-xxl-5{padding-right:3rem!important}.pb-xxl-5,.py-xxl-5{padding-bottom:3rem!important}.pl-xxl-5,.px-xxl-5{padding-left:3rem!important}
  .m-xxl-auto{margin:auto!important}.mt-xxl-auto,.my-xxl-auto{margin-top:auto!important}.mr-xxl-auto,.mx-xxl-auto{margin-right:auto!important}.mb-xxl-auto,.my-xxl-auto{margin-bottom:auto!important}.ml-xxl-auto,.mx-xxl-auto{margin-left:auto!important}

}