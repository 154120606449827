.tienda-menu-container{
	height: 200px;
	width: 100%;
	background: gray;

	position: absolute;
	top: 65px;
	left: 0;
}

.dropdown-menu-center {
  left: 50% !important;
  right: auto !important;
  text-align: center !important;
  transform: translate(-50%, 25%) !important;
}
