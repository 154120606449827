.header-login-menu.menu-usuario .dropdown-menu-center {
  left: 50% !important;
  right: auto !important;
  transform: translate(-50%, 10%) !important;
  text-align: left !important;
}

.header-login-menu.menu-usuario .fa {
  width: 20px;
}

.menu-usuario-icon{
  border: none;
  color: white;
  text-align: center;
  opacity: 0.8;
  transition: 0.3s;
}
.menu-usuario-icon:hover{
  opacity: 1;
}
.login-password-lock {
  padding-left: 15px;
  padding-right: 15px;
}

.forgot-pass .link-forgot-pass {
  text-transform: uppercase;
  font-size: 0.8rem;
  color: #7baec1;
  cursor: pointer;
}
.header-login-menu .dropdown-menu-descargar-lista,
.header-login-menu .dropdown-salir,
.header-login-menu .dropdown-menu-perfil,
.header-login-menu .dropdown-menu-historial-compra,
.header-login-menu .dropdown-menu-resumen-cuenta
{
  padding: .25rem 1.5rem;
}
.nav-link.dropdown-menu-descargar-lista, .nav-link.dropdown-salir{
  font-size: 1rem;
}