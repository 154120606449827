.mainSearch {
  background-color: #ededeb;
  padding: 2rem 0;

  .container {
    @media screen and (min-width: 768px) {
      background: url('../../assets/img/bgSearch.jpg') no-repeat center right/contain;
      padding: 5.25rem 0;
    }
  }

  .slogan {
    max-width: 465px;
  }

}

.mainSearchForm.form-row {
  background-color: #fff;
  border-radius: 1rem;
  padding: 0.4rem 1rem;
  margin: 1rem 0;
  width: 96%;

  @media screen and (min-width: 768px) {
    // margin-top: 1.5rem;
    width: fit-content;
  }

  input.input-search {
    width: 100%;
    border: 0px solid transparent;
    background: transparent;
    color: #492115;

    &::placeholder {
      color: #492115a8;
    }

    @media screen and (min-width: 768px) {
      width: 18rem;
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem rgb(255 0 50 / 0);
    }
  }

  .btn-success {
    color: #fff;
    background-color: #7a3f1b;
    border-color: #4b240c;

    &:hover {
      background-color: #693616;
      border-color: #4b240c;
    }
  }
}