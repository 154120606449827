.esige-productos-container {
  display: flex;
  width: 100%;
  @keyframes esige-productos-animation {
    from {
      margin-left: -30%;
      opacity: 0;
    }
    to {
      margin-left: 0%;
      opacity: 1;
    }
  }
  animation-name: esige-productos-animation;
  animation-iteration-count: 1;
  animation-duration: 0.5s;
}

.esige-productos-container-loader,
.esige-productos-list-loader {
  margin-top: 0px !important;
  width: 100%;
  display: flex;
  > div {
    margin: auto;
  }
}

.esige-productos {
  .filter-price-container.dragging * {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
  }
  .esige-productos-controls {
    width: 100%;
    border-bottom: 1px solid var(--main-color-light);
    //margin-bottom: 20px;
    .breadcrumb {
      margin-top: 10px;
      margin-bottom: 10px;
      border-bottom: none;
      background-color: unset !important;
      a {
        color: #555;
        text-decoration: none;
        background-color: transparent;
        font-weight: bold;
      }
      .breadcrumb-item.active a {
        color: var(--text-color);
        font-weight: bold;
      }
      .breadcrumb-item + .breadcrumb-item::before {
        content: ">";
      }
      .breadcrumb-loader {
        div {
          display: inline-block;
          vertical-align: text-top;
          div {
            width: 10px;
            height: 10px;
          }
        }
      }
    }
    .dropdown {
      .dropdown-menu {
        margin-top: -0px;
      }
    }
  }
  .esige-productos-filter-container {
    h4,
    h5,
    h6 {
      font-weight: 400;
      font-size: 0.985rem;
      letter-spacing: -0.025rem;
    }

    label {
      margin: 0px;
    }
    .esige-productos-filters {
      .filter-title {
        margin-top: 15px;
        margin-bottom: 5px;
      }
      .filter-title:first-child {
        margin-top: 0;
      }
      .filter-loader {
        text-align: center;
      }
      .filter-slider-container {
        display: flex;
        flex-wrap: wrap;
        .filter-slider-label {
          flex: 0 0 50%;
          max-width: 50%;
          margin-bottom: 5px;
          -webkit-user-select: none !important;
          -moz-user-select: none !important;
          -ms-user-select: none !important;
          user-select: none !important;
          &.label-min {
            text-align: left;
          }
          &.label-max {
            text-align: right;
          }
        }
        .range-slider {
          flex: 0 0 93%;
          max-width: 93%;
          margin: 0 auto;
        }
      }
      .filter-inputs-container {
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
        .filter-inputs-from {
          flex: 0 0 40%;
          max-width: 40%;
          padding-right: 2%;
        }
        .filter-inputs-to {
          flex: 0 0 40%;
          max-width: 40%;
          padding-right: 2%;
        }
        .filter-inputs-button {
          flex: 0 0 20%;
          max-width: 20%;
          &.btn {
            padding-left: 5%;
            padding-right: 5%;
          }
        }
        input.form-control {
          padding: 0.187rem 0.375rem;
        }
      }
      ul {
        list-style-type: none;
        padding: 0;
        margin: 0 auto;
        margin-bottom: 10px;
        display: block;
        min-width: 100%;
        max-height: 100vh;
        overflow-y: scroll;
        li {
          -webkit-user-select: none !important;
          -moz-user-select: none !important;
          -ms-user-select: none !important;
          user-select: none !important;
          margin-bottom: 5px;
          div {
            display: inline-block;
            vertical-align: text-bottom;
            span {
              font-weight: 100;
            }
          }
        }
        button.btn {
          min-width: 100%;
        }
      }
    }
  }
  .esige-productos-list {
    .product-container {
      * {
        text-decoration: none;
      }
      text-decoration: none;
      min-height: 300px;

      @media screen and (min-width: 767px) {
        min-height: 350px;
      }

      overflow: hidden;

      &.no-price {
        // min-height: 350px;
        // margin-bottom: -2rem;
        .product-slider {
          margin-top: 0.5rem;
          min-height: 150px;
          @media screen and (min-width: 767px) {
            margin-bottom: 16px;
          }
        }
      }
      cursor: pointer;
      margin-bottom: 2rem;
      transition: all 0.15s linear;
      border: 1px solid #b9b9b957;
      background-color: white;
      padding-top: 10px;
      padding-bottom: 10px;

      @media screen and (min-width: 767px) {
        min-width: 196px;
      }

      .product-slider {
        padding-left: 10px;
        padding-right: 10px;
        margin-top: -5px;

        .carousel {
          min-height: 150px;
          max-height: 150px;
          @media screen and (min-width: 767px) {
            min-height: 200px;
            max-height: 200px;
          }
          display: flex;
          align-items: center;
        }
        .carousel-indicators .active {
          background-color: rgb(0, 102, 255);
        }
        .carousel-indicators li {
          background-color: #fff;
          box-shadow: 1px 1px 1px 1px #868686;
          height: 10px;
          width: 10px;
          border-radius: 100%;
          border-top: 1px solid transparent;
          border-bottom: 1px solid transparent;
        }
        .carousel-control-prev,
        .carousel-control-next {
          height: 20%;
          margin-top: 40%;
          transition: opacity 0.125s ease-in-out;
          &:hover {
            background-color: white;
            opacity: 1;
          }
        }
        .carousel-control-prev {
          border: 1px solid #e2e2e2;
          border-radius: 0px 3px 3px 0px;
          margin-left: -10px;
          border-left: none;
        }
        .carousel-control-next {
          border: 1px solid #e2e2e2;
          border-radius: 3px 0 0 3px;
          margin-right: -10px;
          border-right: none;
        }
        img {
          right: 0;
          top: 0;
          width: 100%;
          height: 80%;
          object-fit: contain;
          max-height: 160px;
        }
        .carousel-control-next-icon {
          color: black;
          background-image: unset;
          &::before {
            font-family: "FontAwesome";
            content: "\f054";
          }
        }
        .carousel-control-prev-icon {
          color: black;
          background-image: unset;
          &::before {
            font-family: "FontAwesome";
            content: "\f053";
          }
        }
        .carousel {
          .carousel-loader {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
      .product-body {
        color: black;
        .product-price {
          font-size: 2rem;
          text-align: center;
          margin: -0.75rem 0 -0.25rem;
          position: relative;
          z-index: 2;
        }
        .product-title {
          font-size: 1rem;
          text-align: center;
          padding: 0 0.5rem 0.25rem;
          text-transform: uppercase;
          color: #20a8d8;
          height: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 48px;
          margin-top: 0.25rem;
          line-height: 15px;

          // Primera letra en mayúscula
          // &::first-letter {
          //     text-transform: capitalize;
          // }
        }
        .product-price + .product-title {
          font-size: 1rem;
          min-height: 60px;
          margin-top: -1rem;
        }
      }
      &:hover {
        border-radius: 4px 4px 0 0;
        box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.2);
        outline: none;
      }
    }
  }
}

.inputGroup {
  display: block;
  position: relative;
  label {
    border-radius: 4px;
    color: #000;
    cursor: pointer;
    display: block;
    font-size: 12px;
    overflow: hidden;
    padding: 3px 5px 3px 26px;
    position: relative;
    text-align: left;
    text-transform: uppercase;
    transition: color 200ms ease-in;
    width: 100%;
    z-index: 2;
    &:before {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      content: "";
      position: absolute;
      left: 0%;
      top: 50%;
      width: 80%;
      transform: translate(-50%, -50%) scale3d(1, 1, 1);
      transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
      opacity: 0;
      z-index: -1;
    }
    &:after {
      padding: 1px;
      content: "";
      width: 15px;
      height: 15px;
      font: normal normal normal 10px/1 none;
      color: var(--bg-content);
      border: 2px solid #d1d7dc;
      border-radius: 25%;
      z-index: 1;
      position: absolute;
      left: 5px;
      text-align: center;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      transition: all 200ms ease-in;
    }
    &:hover {
      background-color: #ccc;
    }
  }
  // input:checked ~ label {
  //   &:before {
  //     transform: translate(-50%, -50%) scale3d(56, 56, 1);
  //     opacity: 1;
  //   }
  //   &:after {
  //     background-color: #422883;
  //     border-color: #422883;
  //   }
  // }
  input {
    width: 32px;
    height: 32px;
    order: 1;
    z-index: 2;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    visibility: hidden;
  }
}

/* Filtros de productos */
a.inputGroup {
  label {
    &:before {
      display: none;
    }
    &:after {
      display: none;
    }
  }
}

.filter-container {
  display: flex;
  flex-direction: column;
}

.filter-title {
  color: #20a8d8;
  border-bottom: 1px dotted #20a8d8;

  h5 > i {
    float: right;
    margin: 2px 4px 0 0;
  }
}

div.show + div.filter-title > h5 > i::before,
div.show ~ div.filter-title > h5 > i::before {
  content: "\F077";
}

// **** PAGINACIÓN ****
.pagination {
  justify-content: center;

  button {
    padding: 0.4rem 0.75rem;
    font-size: 1rem;
    &:not(.d-none) {
      border-radius: 10px 0 0 0;
    }
    &:not(.d-none) ~ button:not(.d-none) {
      border-radius: 0;
    }
    &:last-child {
      border-radius: 0 0 10px 0 !important;
    }
  }
}

.btn:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

select {
  background: transparent;
  padding: 0.25rem 1rem;
}

.modalFilter {
  max-width: max-content !important;
}
.modalFilter .modal-body {
  overflow-y: auto;
  max-height: 80vh;
  @media (max-height: 768px) {
    max-height: 70vh;
  }
}
.modal__filters > ul {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0.4rem 2rem;
  padding-left: 0px;
  grid-auto-flow: column dense;

  li {
    list-style: none;
    height: 25px;
    .inputGroup {
      display: flex;
    }
  }
}

@media (max-width: 600px) {
  .btn-consult-stock {
    padding: 6.25px 5.25px;
    font-size: 0.75rem;
  }
  div.px-1.col-6.col-sm-6.col-md-4.col-lg-3 {
    .btn-consult-stock {
      padding: 7.25px 2.25px;
      font-size: 0.7rem;
    }
  }

  .product-container .product-body .product-price {
    font-size: 1.7em !important;
  }
}

@media (max-width: 352px) and (max-width: 360px) {
  .esige-productos-controls.px-4.px-md-0 .d-inline.ml-auto.my-auto {
    .ml-auto.d-inline.dropdown.nav-item {
      margin: 0px !important;
      margin-right: 1px !important;
    }
    .ml-1.d-inline.dropdown.nav-item {
      margin: 0px !important;
      left: 2px !important;
    }
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 660px;
  }
}

.product {
  &-body {
    text-align: center;
  }
  &__off {
    background: red;
    color: #fff;
    font-weight: 700;
    letter-spacing: 0.05rem;
    padding: 0 1rem;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    transform: rotate(-45deg) translate(-24px, -16px);

    @media screen and (min-width: 767px) {
      transform: rotate(-35deg) translate(-24%, -75%);
    }

    width: 66%;
    z-index: 200;
  }

  &__offdisc {
    font-size: 0.7rem;
    line-height: 0.75rem;
    padding: 0.1rem 1.25rem;
    letter-spacing: -0.001rem;
    transform: rotate(-35deg) translate(-22.5%, -66%);
    background: #045c0221;
    color: #28623c;
  }

  &__code {
    position: absolute;
    right: 5px;
    background: #ededed;
    padding: 0 0.75rem;
    border-radius: 4px 0 0 4px;
    font-size: 0.85rem;
    z-index: 250;
    border-bottom: 1px solid #c6c6c6;
  }
  &__marca {
    background: #02406f;
    border-bottom: 1px solid #c6c6c6;
    border-radius: 4px;
    color: #fff;
    display: inline-block;
    font-size: 0.85rem;
    font-weight: 500;
    padding: 0 0.75rem;
    text-align: center;
    z-index: 250;
    margin-top: 0.5rem;
  }

  &__data {
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-top: 1px solid #dedede;
    padding-top: 0.4rem;
  }

  &__oldPrice {
    font-size: 80%;
    font-weight: 400;
    text-decoration: line-through;
  }
  &__discount {
    bottom: -1px;
    color: #555;
    font-size: 1.1rem;
    margin-left: 0.5rem;
    position: relative;
  }
}

.fa-chevron-up {
  color: dimgray;
  padding-bottom: 1px;
}
.fa-chevron-down {
  color: dimgray;
  padding-bottom: 1px;
}

.bt-1 {
  border-top: 1px solid #c8ced3;
}

.bb-none {
  border-bottom: none;
}

.bt-none {
  border-bottom: none;
}

.p-1110 {
  padding: 1px 1px 1px 0px;
}

.p-2220 {
  padding: 2px 2px 2px 0px;
}

.filter-options-container {
  background-color: #fafafa;
}

.imagebanner{
  @media screen and (max-width: 792px){
    width: 100%;
  }
  @media screen and (min-width: 792px){
    max-height: 400px;
    width: auto;
  }
}

.responsive-button {
  @media (max-width: 250px) {
    font-size: 12px;
   }
  @media (min-width: 251px) {
    font-size: 1rem;
   }
}
