.productItem {
  width: 100%;
  background-color: #fff;
  border: 1px solid #e9e9e9;
  position: relative;
  padding: 0.25rem 0.75rem;
  transition: all ease-in-out .2s;
  border-radius: .05rem;
  box-shadow: 0rem 0.1rem .25rem #00000000;
  margin-bottom: -1px;
  display: grid;
  grid-template-columns: 2fr 6fr 2fr;
  justify-items: center;
  
  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 9fr 2fr 1fr 1fr 1fr;
    // grid-template-columns: 1fr 9fr 2fr;

    & > div {
      margin: 0 1rem !important;
    }
  }

  &:nth-child(2n) {
    background-color: #ececec9c;
  }

  &:hover {
    border: 1px solid #284fff;
    box-shadow: 0rem 0.25rem .5rem #0000002a;
    transform: scale(1.01);
    z-index: 999;
    margin-bottom: -5px;

    // .productItem__photo {
    //   transform: scale(1.01);
    // }
  }

  
  & > button {
    align-self: center;
    padding: .2rem 1rem;
  }
  & > h2 {
    // text-align: center;

    @media screen and (min-width: 768px) {
      justify-self: start;
      margin-left: .5rem;
    }
  }

  // tag off
  &__off {
    font-family: "Roboto Condensed", sans-serif;
    position: absolute;
    left: 0.35rem;
    top: 0;
    // background: #f6a32c;
    color: #fff;
    // padding-top: 2px;
    // padding-left: 0.5rem;
    // padding-right: 0.5rem;
    // padding-bottom: 15px;
    line-height: 1;

    // &:before,
    // &:after {
    //   content: '';
    //   position: absolute;
    //   bottom: -5px;
    //   height: 30%;
    //   background: inherit;
    // }
    // &:before {
    //   left: 0;
    //   width: 50%;
    //   transform: skew(0deg, -35deg);
    // }
    // &:after {
    //   right: 0;
    //   width: 50%;
    //   transform: skew(0deg, 35deg);
    // }

    &--orangeDark {
      background: #e5620d;
    }

    &--red {
      background: #e52233;
    }
    &--Promo {
      top: 0.4rem;
      left: -0.05rem;
      font-weight: bold;
    }

    span {
      display: block;
    }
  }

  &__photoContainer {
    height: 60px !important;
    display: grid;
    margin: -.5rem 0;
   
  }

  // photo
  &__photo {
    object-fit: cover;
    max-height: 60px;
    border-radius: 5rem !important;
    border: 1px solid #dedede83;
    // display: none;

    @media screen and (max-width: 767px) {
      grid-row-start: 1;
      grid-row-end: 3;
      margin-top: 0.75rem;
    }
  }

  // title
  &__title {
    font-size: 1rem;
    // height: 42px;
    // line-height: .965rem;
    // text-align: center;
    margin: 0;
    // cursor: pointer;
    display: flex;
    // justify-content: center;
    align-items: center;
  }

  // prince
  &__price {
    line-height: 0.95rem;
    margin: .4rem 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .price {
      color: #49558b;
      font-size: 1.4375rem;
      font-weight: bold;
      line-height: 1;
      text-align: center;

      &__offer:before {
        content: "Oferta";
        color: #1f729b;
        display: block;
        font-size: 0.8125rem;
      }
    }

    .priceOld {
      color: #9d9d9c;
      display: flex;
      margin: 0;
      align-self: center;
    }
  }
}

.productItem {

  button.btn-productobox {
    margin-top: 0.5rem;
    width: 100%;
    color: #28a745;
    background-color: rgb(255, 255, 255);
    border-color: #28a745;
  }

  &:hover>button.btn-productobox {
    color: white;
    background-color: #28a745;
    border-color: #28a745;
  }
}