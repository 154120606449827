.producto-box {
  border: 1px solid #d9d9d9;
  padding-bottom: .5rem;
  transition: all .3s ease-in-out;

  &:hover {
    border: 1px solid var(--primary-color);
    box-shadow: 0 0 1rem #cccccc;
  }

  &__data {
    padding: 0rem;
  }
  &__figure {
    position: relative;
  }
  &__img {
    max-width: 100%;
    min-height: 300px;
    max-height: 300px;
    display: block;
    margin: auto;
  }
  &__off {
    position: absolute;
    left: 0;
    z-index: 2;
    background: var(--producto-box-off-background);
    padding: 0.6rem;
    color: #fff;
    line-height: 1rem;
    border-radius: 0 1.5rem 1.5rem 1.5rem;
    font-weight: bold;
  }
  &__title {
    cursor: pointer;
    color: rgb(39, 39, 39);
    padding: 0 0.25rem;
    font-size: 1.25rem;
    line-height: 1.25rem;
    min-height: 25px;
    font-weight: bold;
    height: 60px;
    display: flex;
    align-items: center;
    //justify-content: center;
  }
  &__category {
    color: var(--primary-color);
    background-color: var(--second-color);
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    padding-left: 0.2rem;
    font-size: 0.95rem;
    font-weight: bolder;
    margin-bottom: 0;
    letter-spacing: .05rem;
  }
  &__descr {
    padding: 0 0.25rem;
    font-size: 0.75rem;
    line-height: 1rem;
    min-height: 35px;
    height: 95px;
    color: gray;
    font-weight: bolder;

    div {
      width: 100%;
      height: inherit;
      overflow: hidden;
      text-overflow: ellipsis;

      display: block;
      display: -webkit-box;
      max-width: 100%;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
    }
  }
  &__masinfolink {
    padding: 0 ;//0.25rem;
    font-size: 0.75rem;
    line-height: 1rem;
    cursor: pointer;
  }
  .esige-quantity-select {
    width: 100px;
    .product-quantity {
      margin: 0 !important;
      width: inherit;
    }
    input {
      width: 40px;
    }
  }
  &__prices {
    display: flex;
    justify-content: space-around;
  }
  &__pricesOff {
    // color: #9B9B9B;
    color: white;
    text-decoration: line-through;
    min-height: 25px;
    line-height: 1.1rem;
    font-weight: 700;
    text-align: center;
    padding: 0;
    font-weight: bold;
  }

  &__price {
    //color: #f57e00;
    color: white;
    min-height: 25px;
    line-height: 1.1rem;
    font-weight: 700;
    text-align: center;
    font-size: 0.8rem;
  }

  &__pricesOff + &__price {
    padding: 0;
    text-align: left;
    font-weight: bold;
  }

  .btn {
    width: 100%;
    text-transform: uppercase;
  }
  .button-comprar {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    opacity: 0.9;
  }
  .button-comprar:hover,
  .button-comprar:active {
    opacity: 1 !important;
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
    color: white;
  }
  .carousel-control-next {
    background: #ffffff28;
    border-radius: 10px 0px 0px 10px;
    right: 0px;
    opacity: 0.4;
    .carousel-control-next-icon {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
    }
  }
  .carousel-control-prev {
    background: #ffffff28;
    border-radius: 0px 10px 10px 0px;
    left: 0px;
    opacity: 0.4;
    .carousel-control-prev-icon {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
    }
  }
  .carousel-control-prev:hover,
  .carousel-control-next:hover {
    opacity: 0.8;
  }
}

.position-md-absolute-bottom{
  @media screen and (min-width: 576px){
    position: absolute !important;
    bottom: 0;
  }
}
